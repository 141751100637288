/* Headings
----------------------------------------------------------------------------- */
%headings {
    font-family: $font-headings;
    font-weight: 400;
    text-transform: uppercase;
}

h3, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h2 {
    margin-top: 0;
    margin-bottom: 2rem;
}

h1, h2, h3, h4 {
    @extend %headings;
}

/* Paragraphs
----------------------------------------------------------------------------- */

p, ol, ul, dl, blockquote, figure {
    margin-top: 0;
    margin-bottom: 1rem;
}

hr {
    margin: 1rem 0;
}
