* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-base;
}

.masthead {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 35rem;
    padding: 15rem 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 75%, #000000 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    text-align: center;
    color: white;

    @media (min-width: 992px) {
        height: 100vh;
        padding: 0;
    }

    h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: white;
        @extend %headings;

        @media (min-width: 768px) {
            font-size: 4rem;
            line-height: 4rem;
        }

        @media (min-width: 992px) {
            font-size: 6.5rem;
            line-height: 6.5rem;
            letter-spacing: 0.8rem;
        }
    }

    p {
        max-width: 20rem;
        font-size: 1rem;

        @media (min-width: 992px) {
            max-width: 30rem;
            font-size: 1.5rem;
        }
    }
}


#about {
    padding: 2rem 0 4rem 0;
    background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0.9) 75%, rgba(0, 0, 0, 0.8) 100%);
    text-align: center;
    color: white;

    img {
        max-width: 100%;
        height: auto;

        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 5rem;
        color: white;

        @media (min-width: 992px) {
            padding-left: 2rem;
            text-align: left;
        }
    }
}


#work {
    padding: 2rem;

    @media (min-width: 992px) {
        padding: 5rem;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        color: white;
    }

    .work {
        background-color: black;
        color: white;

        .work-text {
            padding: 3rem;
            font-size: 90%;

            @media (min-width: 992px) {
                padding: 5rem;

                hr {
                    border-color: $brand-alt;
                    border-width: 0.25rem;
                    width: 30%;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}


#contact {
    padding-top: 5rem;
    background-color: black;

    h2 {
        color: white;
    }

    .card {
        border: 0;
        border-bottom: 0.25rem solid $brand-secondary;

        h4 {
            font-size: 0.8rem;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
        }

        hr {
            border-color: $brand-alt;
            border-width: 0.25rem;
            width: 3rem;
            margin-left: auto;
            margin-right: auto;
        }

        a {
            color: $brand-primary;

            @media (min-width: 992px) {
                font-size: 30px;
            }

            &:hover {
                color: $brand-secondary;
            }
        }
    }
}

.footer {
    padding: 5rem 0 2rem 0;
    text-align: center;
    background-color: black;
    color: white;
}
