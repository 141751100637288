/* ------ Fonts ------ */

@import url('https://fonts.googleapis.com/css?family=Muli|Roboto&display=swap');

$font-headings: 'Muli', sans-serif;
$font-base: 'Roboto', sans-serif;

/* ------ Layout ------ */
$font-size-base: 16px;

/* ------ Headings ------ */

/* ------ Animation ------ */

/* ------ Colors ------ */

$brand-primary: #2A443B;
$brand-secondary: #FFC30D;
$brand-alt: #889aa3;

/* ------ Media breaks ------ */
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$screen-sm-max: 767px;
$screen-md-max: 991px;
$screen-lg-max: 1199px;
